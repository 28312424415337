export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Sogility',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission': `Sogility’s mission is to increase soccer players’ skills, confidence, and physical development with personalized training through the use of technology and individualized data monitoring. Discover what kind of soccer player you want to become with Sogility's six facets of world-class training.`,
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
};
